



























































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'

@Component({
  directives: { mask },
  components: {
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})

export default class ScheduleForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) scheduleId!: string

  done = true
  form: Record<string, any> = {
    datetime: '',
    year: '',
    month: '',
    day: '',
    part: '',
    title: '',
    description: '',
    eventId: '',
    order: 999,
    module: false,
    bordered: true,
    published: true,
    confirm: '',
    errors: {
      datetime: '',
      year: '',
      month: '',
      day: '',
      part: '',
      title: '',
      description: '',
      eventId: '',
      module: '',
      bordered: '',
      published: '',
      order: '',
      confirm: ''
    }
  }

  parts = [
    {
      name: 'morning',
      prettyName: 'Manhã (06:00 até 11:59)'
    },
    {
      name: 'afternoon',
      prettyName: 'Tarde (12:00 até 17:59)'
    },
    {
      name: 'evening',
      prettyName: 'Noite (18:00 até 23:59)'
    },
    {
      name: 'night',
      prettyName: 'Madrugada (00:00 até 05:59)'
    }
  ]

  created () {
    this.form.eventId = this.eventId

    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        datetime: '08:00 às 10:00',
        title: 'Programação Científica',
        description: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li><s>Lista 2</s></li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `
      })
    }

    if (this.type === 'create') {
      axios.get(`/event/${this.eventId}/schedules`)
        .then(() => {
          this.form.order = 0
        })
    } else if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/schedules/${this.scheduleId}`)
        .then(response => {
          const schedule = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(schedule).forEach((key: string) => {
            this.form[key] = key in this.form ? schedule[key] : this.form[key]
          })
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/schedules/${this.scheduleId}`
      : `/event/${this.eventId}/schedules`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const successVerb = this.type === 'edit' ? 'editada' : 'cadastrada'

    const flashMessage = {
      message: `A Programação Científica foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({
      name: 'EventSchedules',
      params: {
        id: this.eventId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      eventId: '',
      confirm: ''
    }
  }
}
